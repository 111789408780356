import React, { useState } from "react";
import "../styles/components/Form.scss";

const Form = ({ onGenerateClick, onCopyToClipboardClick }: FormProps) => {
  const [name, setName] = useState("");
  const [occupation, setOccupation] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [linkedInUrl, setLinkedInUrl] = useState("");
  const [twitterUrl, setTwitterUrl] = useState("");
  const [discordUrl, setDiscordUrl] = useState("");
  const [telegramUrl, setTelegramUrl] = useState("");

  const isButtonDisabled = (): boolean => {
    return name === "" || occupation === "" || phone === "" || email === "";
  };

  const buttonDisabled = isButtonDisabled();

  return (
    <div className="form">
      <label htmlFor="name">Name</label>
      <input
        id="name"
        placeholder="Kai Crayford"
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required={true}
      />

      <label htmlFor="occupation">Occupation</label>
      <input
        id="occupation"
        placeholder="Director / Founder"
        type="text"
        value={occupation}
        onChange={(e) => setOccupation(e.target.value)}
        required={true}
      />

      <label htmlFor="phone">Phone</label>
      <input
        id="phone"
        placeholder="07532826780"
        type="tel"
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        required={true}
      />

      <label htmlFor="email">Email</label>
      <input
        id="email"
        placeholder="info@austinwerner.tech"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required={true}
      />

      <label htmlFor="linkedin">LinkedIn (Optional)</label>
      <input
        id="linkedin"
        placeholder=""
        type="url"
        value={linkedInUrl}
        onChange={(e) => setLinkedInUrl(e.target.value)}
        required={false}
      />

      <label htmlFor="twitter">Twitter (Optional)</label>
      <input
        id="twitter"
        placeholder=""
        type="url"
        value={twitterUrl}
        onChange={(e) => setTwitterUrl(e.target.value)}
        required={false}
      />

      <label htmlFor="discord">Discord (Optional)</label>
      <input
        id="discord"
        placeholder=""
        type="url"
        value={discordUrl}
        onChange={(e) => setDiscordUrl(e.target.value)}
        required={false}
      />

      <label htmlFor="telegram">Telegram (Optional)</label>
      <input
        id="telegram"
        placeholder=""
        type="url"
        value={telegramUrl}
        onChange={(e) => setTelegramUrl(e.target.value)}
        required={false}
      />

      <div className="buttons">
        <button
          disabled={buttonDisabled}
          className="button"
          onClick={() => {
            onGenerateClick({
              name,
              occupation,
              phone,
              email,
              linkedInUrl,
              twitterUrl,
              discordUrl,
              telegramUrl,
            });
          }}
        >
          Generate
        </button>
        {/*<label className="or">or</label>*/}
        {/*<button*/}
        {/*  disabled={buttonDisabled}*/}
        {/*  className="button"*/}
        {/*  onClick={() => {*/}
        {/*    onCopyToClipboardClick({*/}
        {/*      name,*/}
        {/*      occupation,*/}
        {/*      phone,*/}
        {/*      email,*/}
        {/*      linkedInUrl,*/}
        {/*      twitterUrl,*/}
        {/*      discordUrl,*/}
        {/*      telegramUrl,*/}
        {/*    });*/}
        {/*  }}*/}
        {/*>*/}
        {/*  Copy to clipboard*/}
        {/*</button>*/}
      </div>
    </div>
  );
};

export default Form;

type FormProps = {
  onGenerateClick: (data: FormData) => void;
  onCopyToClipboardClick: (data: FormData) => void;
};

export type FormData = {
  name: string;
  occupation: string;
  phone: string;
  email: string;
  linkedInUrl: string;
  twitterUrl: string;
  discordUrl: string;
  telegramUrl: string;
};
