import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Form, { FormData } from "./components/Form";
import Nav from "./components/Nav";
import ReactDOMServer from "react-dom/server";
import "./styles/App.scss";
import Generated from "./components/Generated";
import copyFormatted from "./utils/copyFormattedHTML";

function App() {
  const navigate = useNavigate();

  const modalRef = useRef(null);

  const onGenerateClick = ({
    name,
    occupation,
    phone,
    email,
    linkedInUrl,
    twitterUrl,
    discordUrl,
    telegramUrl,
  }: FormData) => {
    let query = `?name=${encodeURIComponent(name)}`;
    query += `&occupation=${encodeURIComponent(occupation)}`;
    query += `&phone=${encodeURIComponent(phone)}`;
    query += `&email=${encodeURIComponent(email)}`;
    linkedInUrl &&
      (query += `&linkedin-url=${encodeURIComponent(linkedInUrl)}`);
    twitterUrl && (query += `&twitter-url=${encodeURIComponent(twitterUrl)}`);
    discordUrl && (query += `&discord-url=${encodeURIComponent(discordUrl)}`);
    telegramUrl &&
      (query += `&telegram-url=${encodeURIComponent(telegramUrl)}`);

    navigate("/generated" + query);
  };
  const onCopyToClipboardClick = ({
    name,
    occupation,
    phone,
    email,
    linkedInUrl,
    twitterUrl,
    discordUrl,
    telegramUrl,
  }: FormData) => {
    let result = "<meta charset='utf-8'>";
    result += ReactDOMServer.renderToString(
      <Generated
        name={name}
        occupation={occupation}
        phone={phone}
        email={email}
        linkedInUrl={linkedInUrl}
        twitterUrl={twitterUrl}
        discordUrl={discordUrl}
        telegramUrl={telegramUrl}
      />
    );

    if (modalRef.current) {
      (modalRef.current as any).style.visibility = "initial";
    }

    copyFormatted(result);

    setTimeout(() => {
      if (modalRef.current) {
        (modalRef.current as any).style.visibility = "hidden";
      }
    }, 2000);
  };

  return (
    <div className="App">
      <Nav />
      <div className="wrapper">
        <div className="labels">
          <h1 className="title">Signature generator</h1>
          <p className="description">
            Generate email signatures with ease. Just fill in your data in the
            form and click the button.
          </p>
          <p className="description">
            After you press <b>Generate</b> you will be redirected to the page
            with your signature. To use it, just press <b>Ctrl+A</b> and{" "}
            <b>Ctrl+C</b> (<b>Cmd+A</b> and <b>Cmd+C</b> for macOS).
            <br /> For better results, we recommend using Chrome.
          </p>
          {/*<p className="description">*/}
          {/*  If you press <b>Copy to clipboard</b> your signature will be*/}
          {/*  automatically copied. After that, just paste it with <b>Ctrl+V</b> (*/}
          {/*  <b>Cmd+V</b> for macOS) where you need it.*/}
          {/*</p>*/}
        </div>

        <Form
          onCopyToClipboardClick={onCopyToClipboardClick}
          onGenerateClick={onGenerateClick}
        />

        <div className="modal-container">
          <p className="copy-success" ref={modalRef}>
            Signature successfully copied to clipboard!
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;
