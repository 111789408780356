export default function copyFormatted(html: string) {
  const type = "text/html";
  const blob = new Blob([html], { type });
  const data = [new ClipboardItem({ [type]: blob })];

  navigator.clipboard.write(data).then(
    function () {
      /* success */
    },
    function () {
      /* failure */
    }
  );
}
